import { FuseNavigationItem } from "@fuse/components/navigation";

export default function SalesAdvisorNav() {
  const nav: FuseNavigationItem[] = [
    {
      id: "crm",
      title: "CRM",
      subtitle: "Interactua con los clientes",
      type: "group",
      icon: "heroicons_outline:home",
      children: [
        {
          id: "dashboard",
          title: "Dashboard",
          type: "basic",
          icon: "heroicons_outline:chart-pie",
          link: "/dashboard/advisor",
        },
        {
          id: "crm.clientes",
          title: "Clientes",
          type: "basic",
          icon: "heroicons_outline:users",
          link: "/crm/customers",
        },
        {
          id: "usersProspects",
          title: "Leads",
          type: "basic",
          icon: "heroicons_outline:user",
          link: "/crm/prospects",
        },
        {
          id: "crm.opportunity",
          title: "Oportunidades",
          type: "basic",
          icon: "heroicons_outline:shopping-cart",
          link: "/crm/opportunity",
        },
        {
          id: "crm.upcoming-opportunities",
          title: "Oportunidades por vencer",
          type: "basic",
          icon: "heroicons_outline:clock",
          link: "/crm/upcoming-opportunities",
        },
        {
          id: "crm.lost-sale",
          title: "Venta Perdida",
          type: "basic",
          icon: "thumb_down",
          link: "/crm/lost-sale",
        },
        {
          id: "activities",
          title: "Calendario de Actividades",
          type: "basic",
          icon: "heroicons_outline:calendar",
          link: "/crm/activities",
        },
      ],
    },
    {
      id: "inventory",
      title: "Inventario",
      subtitle: "Administra los productos y proveedores",
      type: "group",
      icon: "heroicons_outline:home",
      children: [
        {
          id: "inventory.products",
          title: "Productos",
          type: "basic",
          icon: "heroicons_outline:shopping-bag",
          link: "/inventory/products",
        },
      ],
    },
    // {
    //   id: "purchase",
    //   title: "Compras",
    //   subtitle: "Administra solicitudes de compra",
    //   type: "group",
    //   icon: "heroicons_outline:arrow-path-rounded-square",
    //   children: [
    //     {
    //       id: "purchase.new-purchase-order",
    //       title: "Solicitudes de compra",
    //       type: "basic",
    //       icon: "heroicons_outline:queue-list",
    //       link: "/purchase/purchase-list",
    //     },
    //   ],
    // },
  ];

  return nav;
}
