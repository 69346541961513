import { LoadingDialogComponent } from "#root/shared/components/loading-dialog/loading-dialog.componente";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Observable, finalize } from "rxjs";

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  constructor(private dialog: MatDialog) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const showModalHeader = req.headers.get("showModal");
    let showModal = true;

    if (showModalHeader == "no") {
      showModal = false;
    }

    let dialogRef: MatDialogRef<LoadingDialogComponent, unknown> | null = null;

    if (req.url.includes("Comment") && req.method === "POST") {
      return next.handle(req);
    }

    if (req.url.includes("Opportunity/UpdatePosition") && req.method === "PUT") {
      return next.handle(req);
    }

    if ((req.method === "POST" || req.method === "PUT" || req.method === "DELETE") && showModal) {
      dialogRef = this.dialog.open(LoadingDialogComponent, {
        disableClose: true,
        hasBackdrop: false,
        width: "100vw",
        maxWidth: "100vw",
        height: "100%",
        panelClass: "dialog-no-background",
        enterAnimationDuration: "0s",
        exitAnimationDuration: "1s",
      });
    }

    return next.handle(req).pipe(
      finalize(() => {
        dialogRef?.close();
      })
    );
  }
}
