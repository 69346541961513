import { FuseNavigationItem } from "@fuse/components/navigation";

export default function SuperAdminNav() {
  const nav: FuseNavigationItem[] = [
    {
      id: "crm",
      title: "CRM",
      subtitle: "Interactua con los clientes",
      type: "group",
      icon: "heroicons_outline:home",

      children: [
        {
          id: "crm.dashboard.main",
          title: "Dashboard",
          type: "basic",
          icon: "heroicons_outline:chart-pie",
          link: "/dashboard/main",
        },
        {
          id: "crm.dashboard.merch",
          title: "Dashboard Mercadeo",
          type: "basic",
          icon: "heroicons_outline:chart-pie",
          link: "/dashboard/merch",
        },
        {
          id: "crm.clientes",
          title: "Clientes",
          type: "basic",
          icon: "heroicons_outline:users",
          link: "/crm/customers",
        },
        {
          id: "crm.opportunity",
          title: "Oportunidades",
          type: "basic",
          icon: "heroicons_outline:shopping-cart",
          link: "/crm/opportunity",
        },
        {
          id: "crm.upcoming-opportunities",
          title: "Oportunidades por vencer",
          type: "basic",
          icon: "heroicons_outline:clock",
          link: "/crm/upcoming-opportunities",
        },
        {
          id: "crm.lost-sale",
          title: "Venta Perdida",
          type: "basic",
          icon: "thumb_down",
          link: "/crm/lost-sale",
        },
        {
          id: "crm.wishList",
          title: "Lista de Deseos",
          type: "basic",
          icon: "heroicons_outline:sparkles",
          link: "/crm/wishList",
        },
        {
          id: "crm.messages",
          title: "Mensajes",
          type: "basic",
          icon: "heroicons_outline:inbox-in",
          link: "/crm/messages",
        },
        {
          id: "crm.newsletter",
          title: "Suscriptores a boletín",
          type: "basic",
          icon: "heroicons_outline:inbox",
          link: "/crm/newsletter",
        },
        {
          id: "crm.financePlan",
          title: "Planes de Financiamiento",
          type: "basic",
          icon: "heroicons_outline:document-report",
          link: "/crm/financePlan",
        },
        {
          id: "crm.adCampaign",
          title: "Campañas Publicitarias",
          type: "basic",
          icon: "heroicons_outline:megaphone",
          link: "/crm/adCampaign",
        },
        {
          id: "activities",
          title: "Calendario de Actividades",
          type: "basic",
          icon: "heroicons_outline:calendar",
          link: "/crm/activities",
        },
        {
          id: "customersWallet",
          title: "Asesores de venta",
          type: "basic",
          icon: "heroicons_outline:briefcase",
          link: "/crm/salesAdvisors",
        },

        {
          id: "usersProspects",
          title: "Leads",
          type: "basic",
          icon: "heroicons_outline:user",
          link: "/crm/prospects",
        },
        {
          id: "pending",
          title: "Pendiente por aprobar",
          type: "collapsable",
          icon: "heroicons_outline:check",
          children: [
            {
              id: "pending.discount",
              title: "Descuentos",
              type: "basic",
              link: "/crm/pending/discount",
            },
          ],
        },
        {
          id: "crm.invoice",
          title: "Facturas",
          type: "basic",
          icon: "heroicons_outline:document-text",
          link: "/crm/invoice",
        },
        {
          id: "crm.delivery-note",
          title: "Entregas",
          type: "basic",
          icon: "mat_outline:delivery_dining",
          link: "/crm/delivery-note",
        },
        {
          id: "crm.business-partners",
          title: "Crear socios de negocio",
          type: "basic",
          icon: "heroicons_outline:user",
          link: "/crm/business-partners",
        },
        {
          id: "reports",
          title: "Reportes",
          type: "collapsable",
          icon: "heroicons_outline:folder-open",
          children: [
            {
              id: "report.customers_advisor",
              title: "Clientes por asesores",
              type: "basic",
              link: "/crm/report/customers-advisor",
            },
            {
              id: "report.quoted_products_customer",
              title: "Productos cotizados por clientes",
              type: "basic",
              link: "/crm/report/quoted-products-client",
            },
            {
              id: "report.advisor_goal",
              title: "Metas de Asesores",
              type: "basic",
              link: "/crm/report/advisor-goal",
            },
            {
              id: "report.top_quote",
              title: "Productos mas cotizados",
              type: "basic",
              link: "/crm/report/top-quote",
            },
            /*{
              id: "report.top_quote_by_cliente",
              title: "Clientes interesados por producto",
              type: "basic",
              link: "/crm/report/clients-by-quoted-product",
            },*/
            {
              id: "report.top_sold",
              title: "Productos mas vendidos",
              type: "basic",
              link: "/crm/report/top-sold",
            },
            {
              id: "report.advisor_activities",
              title: "Actividades de los asesores",
              type: "basic",
              link: "/crm/report/advisor-activities",
            },
            {
              id: "report.opportunity_origins",
              title: "Origenes de oportunidades",
              type: "basic",
              link: "/crm/report/opportunity-origins",
            },
            {
              id: "report.master",
              title: "Reporte de ventas",
              type: "basic",
              link: "/crm/report/master",
            },
            {
              id: "report.master",
              title: "Reporte de ventas perdidas",
              type: "basic",
              link: "/crm/report/master-lost",
            },
            /*{
              id: "report.master_quotations",
              title: "Maestro Cotizaciones",
              type: "basic",
              link: "/crm/report/master-quotations",
            },*/
            {
              id: "report.master-leads",
              title: "Maestro Leads",
              type: "basic",
              link: "/crm/report/master-leads",
            },
            {
              id: "report.master-clients",
              title: "Maestro Clientes",
              type: "basic",
              link: "/crm/report/master-client",
            },
            {
              id: "report.master-products",
              title: "Maestro Productos",
              type: "basic",
              link: "/crm/report/master-product",
            },
            {
              id: "report.top-customers",
              title: "Top ventas clientes",
              type: "basic",
              link: "/crm/report/top-customers",
            },
            {
              id: "report.participation-by-subcategory",
              title: "Participación por subcategoría",
              type: "basic",
              link: "/crm/report/participation-by-subcategory",
            },
            {
              id: "report.inventory-rotation",
              title: "Rotación de inventario",
              type: "basic",
              link: "/crm/report/inventory-rotation",
            },
            {
              id: "report.inventory-analysis-by-warehouse",
              title: "Análisis de inventario por almacén",
              type: "basic",
              link: "/crm/report/inventory-analysis-by-warehouse",
            },
            // {
            //   id: "report.compliance-purchase-advisor",
            //   title: "Cumplimiento por asesores de compra",
            //   type: "basic",
            //   link: "/crm/report/compliance-purchase-advisor",
            // },
            // {
            //   id: "report.create-product-purchase-avarage",
            //   title:
            //     "Cumplimiento con creación de producto por asesores de compra",
            //   type: "basic",
            //   link: "/crm/report/create-product-purchase-avarage",
            // },
            // {
            //   id: "report.purchase-request-avarage",
            //   title:
            //     "Cumplimiento con producto existente por asesores de compra",
            //   type: "basic",
            //   link: "/crm/report/purchase-request-avarage",
            // },
          ],
        },
      ],
    },
    {
      id: "inventory",
      title: "Inventario",
      subtitle: "Administra los productos",
      type: "group",
      icon: "heroicons_outline:home",
      children: [
        {
          id: "inventory.products",
          title: "Productos",
          type: "basic",
          icon: "heroicons_outline:shopping-bag",
          link: "/inventory/products",
        },
        {
          id: "inventory.providers",
          title: "Proveedores",
          type: "basic",
          icon: "heroicons_outline:user-group",
          link: "/inventory/providers",
        },
        {
          id: "inventory.brand",
          title: "Marcas",
          type: "basic",
          icon: "heroicons_outline:hashtag",
          link: "/inventory/brands",
        },
        {
          id: "inventory.categoryProducts.category",
          title: "Categorías",
          type: "basic",
          icon: "heroicons_outline:tag",
          link: "/inventory/categoryProducts/categories",
        },
        {
          id: "inventory.categoryProducts.subcategory",
          title: "Sub categorías",
          type: "basic",
          icon: "heroicons_outline:tag",
          link: "/inventory/categoryProducts/subcategories",
        },
        {
          id: "inventory.characteristic",
          title: "Caracteristicas",
          type: "basic",
          icon: "heroicons_outline:search-circle",
          link: "/inventory/characteristics",
        },
      ],
    },
    // {
    //   id: "purchase",
    //   title: "Compras",
    //   subtitle: "Administra solicitudes de compra",
    //   type: "group",
    //   icon: "heroicons_outline:arrow-path-rounded-square",
    //   children: [
    //     {
    //       id: "purchase.new-purchase-order",
    //       title: "Solicitudes de compra",
    //       type: "basic",
    //       icon: "heroicons_outline:queue-list",
    //       link: "/purchase/purchase-list",
    //     },
    //   ],
    // },
    {
      id: "ecommerce",
      title: "Ecommerce",
      subtitle: "Gestiona los datos del ecommerce",
      type: "group",
      icon: "heroicons_outline:home",
      children: [
        {
          id: "ecommerce.businessData",
          title: "Datos de la empresa",
          type: "basic",
          icon: "heroicons_outline:database",
          link: "/ecommerce/businessData",
        },
        {
          id: "ecommerce.branch",
          title: "Sucursales",
          type: "basic",
          icon: "heroicons_outline:office-building",
          link: "/ecommerce/branch",
        },
        {
          id: "ecommerce.heroSlider",
          title: "Hero Slider",
          type: "basic",
          icon: "heroicons_outline:collection",
          link: "/ecommerce/heroSlider",
        },
        {
          id: "ecommerce.opinions",
          title: "Opiniones",
          type: "basic",
          icon: "heroicons_outline:pencil",
          link: "/ecommerce/opinions",
        },
        {
          id: "ecommerce.banners",
          title: "Banners",
          type: "basic",
          icon: "heroicons_outline:newspaper",
          link: "/ecommerce/banners",
        },
      ],
    },
    {
      id: "globalConfig",
      title: "Configuraciones Globales",
      subtitle: "Configura datos, tipos de datos y estados",
      type: "group",
      icon: "heroicons_outline:home",
      children: [
        {
          id: "globalConfig.salesChannel",
          title: "Canales de ventas",
          type: "basic",
          icon: "mat_solid:attach_money",
          link: "/globalConfig/sales-channel",
        },
        {
          id: "globalConfig.documentType",
          title: "Tipos de Documentos",
          type: "basic",
          icon: "heroicons_outline:document",
          link: "/globalConfig/documentType",
        },
        // {
        //   id: "globalConfig.material-types",
        //   title: "Tipo de Materiales",
        //   type: "basic",
        //   icon: "heroicons_outline:cube",
        //   link: "/globalConfig/material-types",
        // },
        {
          id: "globalConfig.typeOrigins",
          title: "Tipos de Origen",
          type: "basic",
          icon: "mat_outline:account_tree",
          link: "/globalConfig/origin/typeOrigins",
        },
        {
          id: "globalConfig.typeActivity",
          title: "Tipos de Actividades",
          type: "basic",
          icon: "heroicons_outline:calendar",
          link: "/globalConfig/typeActivity",
        },
        // {
        //   id: "globalConfig.purchasePriority",
        //   title: "Prioridades de compra",
        //   type: "basic",
        //   icon: "heroicons_outline:exclamation-triangle",
        //   link: "/globalConfig/purchasePriority",
        // },
        {
          id: "globalConfig.measureUnit",
          title: "Unidades de Medida",
          type: "basic",
          icon: "heroicons_outline:variable",
          link: "/globalConfig/measureUnit",
        },
        {
          id: "globalConfig.status",
          title: "Estados",
          type: "collapsable",
          icon: "heroicons_outline:check-circle",
          link: "/globalConfig/status",
          children: [
            {
              id: "globalConfig.status.typeStatus",
              title: "Estados",
              type: "basic",
              link: "/globalConfig/status/list",
            },
            {
              id: "globalConfig.status.typeStatus",
              title: "Tipos de estados",
              type: "basic",
              link: "/globalConfig/status/typeStatus",
            },
          ],
        },
        {
          id: "globalConfig.locations",
          title: "Locaciones",
          type: "collapsable",
          icon: "heroicons_outline:globe",
          link: "/globalConfig/locations",
          children: [
            {
              id: "globalConfig.locations.regions",
              title: "Regiones",
              type: "basic",
              link: "/globalConfig/locations/regions",
            },
            {
              id: "globalConfig.locations.departments",
              title: "Departamentos",
              type: "basic",
              link: "/globalConfig/locations/departments",
            },
            {
              id: "globalConfig.locations.cities",
              title: "Municipios",
              type: "basic",
              link: "/globalConfig/locations/cities",
            },
          ],
        },
        {
          id: "globalConfig.winReasons",
          title: "Razones de ganancia",
          type: "basic",
          icon: "heroicons_outline:trending-up",
          link: "/globalConfig/winReasons",
        },
        {
          id: "globalConfig.lossReasons",
          title: "Razones de perdida",
          type: "basic",
          icon: "heroicons_outline:trending-down",
          link: "/globalConfig/lossReasons",
        },
        // {
        //   id: "globalConfig.rejectReasons",
        //   title: "Razones de rechazo",
        //   type: "basic",
        //   icon: "heroicons_outline:x-circle",
        //   link: "/globalConfig/rejectReasons",
        // },
        {
          id: "globalConfig.users",
          title: "Usuarios",
          type: "basic",
          icon: "heroicons_outline:users",
          link: "/globalConfig/users",
        },
      ],
    },
  ];

  return nav;
}
