import { FuseNavigationItem } from "@fuse/components/navigation";

export default function PurchaseAdvisorNav() {
  const nav: FuseNavigationItem[] = [
    // {
    //   id: "purchase",
    //   title: "Compras",
    //   subtitle: "Administra solicitudes de compra",
    //   type: "group",
    //   icon: "heroicons_outline:arrow-path-rounded-square",
    //   children: [
    //     {
    //       id: "purchase.new-purchase-order",
    //       title: "Solicitudes de compra",
    //       type: "basic",
    //       icon: "heroicons_outline:queue-list",
    //       link: "/purchase/purchase-list",
    //     },
    //   ],
    // },
    {
      id: "inventory",
      title: "Inventario",
      subtitle: "Administra los productos",
      type: "group",
      icon: "heroicons_outline:home",
      children: [
        
        {
          id: "inventory.providers",
          title: "Proveedores",
          type: "basic",
          icon: "heroicons_outline:user-group",
          link: "/inventory/providers",
        },
      ],
    },
  ];

  return nav;
}
