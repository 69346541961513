import pkg from "../../package.json";

export const environment = {
  version: pkg.version,
  hmr: false,
  production: true,
  baseUrl: "https://crm-ferreteria.azurewebsites.net/api/v1/",
  signalRUrl: "https://crm-ferreteria.azurewebsites.net/hub/notification",
  googleMapskey: "AIzaSyCoF2frpi0FD7ZW0Y2NNiQ8NJ8ZqXiWoug",
  sentryDSN: "https://f2bc158ba143ac2467ceeee32b769d2b@o4505172191608832.ingest.sentry.io/4506344006156288",
  appTitle: (title: string) => `${title ? `${title} | ` : ""}Platino Ferreteria - Panel Administrativo`,
  googleAnalyticsId: "G-NML98RPM2G",
};
