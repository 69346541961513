import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { APP_INITIALIZER, ErrorHandler, NgModule, Provider } from "@angular/core";
import { ErrorStateMatcher, MAT_DATE_LOCALE, MatNativeDateModule } from "@angular/material/core";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ExtraOptions, PreloadAllModules, Router, RouterModule } from "@angular/router";

import * as Sentry from "@sentry/angular-ivy";
import { MarkdownModule } from "ngx-markdown";

import { AppComponent } from "#root/app.component";
import { appRoutes } from "#root/app.routing";
import { appConfig } from "#root/core/config/app.config";
import { CoreModule } from "#root/core/core.module";
import { LayoutModule } from "#root/core/layout/layout.module";
import { mockServices } from "#root/mock-api";

import { FuseModule } from "@fuse";
import { FuseAlertModule } from "@fuse/components/alert";
import { FuseMockApiModule } from "@fuse/lib/mock-api";
import { FuseConfigModule } from "@fuse/services/config";

import { LoadingInterceptor } from "#root/http/interceptors/loading.interceptor";
import { MyErrorStateMatcher } from "#utils/error-state-matcher/error-state-matcher";
import { environment } from "environments/environment";
import { CleanStringsInterceptor } from "./http/interceptors/clean-strings.interceptor";
import { Interceptor } from "./http/interceptors/http-interceptors.service";

const routerConfig: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: "enabled",
  useHash: true,
};

const matPaginatorTranslation = new MatPaginatorIntl();
matPaginatorTranslation.firstPageLabel = "Primera página";
matPaginatorTranslation.itemsPerPageLabel = "Elementos por página";
matPaginatorTranslation.lastPageLabel = "Última página";
matPaginatorTranslation.nextPageLabel = "Siguiente página";
matPaginatorTranslation.previousPageLabel = "Página anterior";
matPaginatorTranslation.getRangeLabel = (page, pageSize, length) => {
  if (length == 0 || pageSize == 0) return `0 de ${length}`;
  length = Math.max(length, 0);

  const startIndex = page * pageSize;
  const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

  return `${startIndex + 1} – ${endIndex} de ${length}`;
};

const providers: Provider[] = [
  {
    provide: MAT_DATE_LOCALE,
    useValue: "es-HN",
  },
  {
    provide: MatPaginatorIntl,
    useValue: matPaginatorTranslation,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: Interceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: LoadingInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: CleanStringsInterceptor,
    multi: true,
  },
  {
    provide: ErrorStateMatcher,
    useClass: MyErrorStateMatcher,
  },
];

if (environment.production && environment.sentryDSN.length) {
  providers.push(
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    }
  );
}

@NgModule({
  declarations: [AppComponent],
  providers,
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, routerConfig),
    // Fuse, FuseConfig & FuseMockAPI
    FuseModule,
    FuseAlertModule,
    FuseConfigModule.forRoot(appConfig),
    FuseMockApiModule.forRoot(mockServices),
    MarkdownModule.forRoot(),
    // DashboardModule,
    MatNativeDateModule,

    // Core module of your application
    CoreModule,

    // Layout module of your application
    LayoutModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
