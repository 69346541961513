// main.ts
import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import * as Sentry from "@sentry/angular-ivy";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

import { hmrBootstrap } from "./hmr";

if (environment.production) {
    enableProdMode();

    if (environment.sentryDSN.length) {
        Sentry.init({
            dsn: environment.sentryDSN,
            integrations: [
                new Sentry.BrowserTracing({
                    routingInstrumentation: Sentry.routingInstrumentation,
                }),
                new Sentry.Replay({
                    networkDetailAllowUrls: [/https:\/\/api\.platinoferreteria\.com\/api\/v1\/([A-Za-z]+(\/[A-Za-z]+)+)/i],
                }),
            ],
            tracesSampleRate: 1.0,
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
            ignoreErrors: [
                "One or more validation errors occurred.",
                "Unknown Error.",
                "Unknown Error",
                "Usted no esta autorizado para ejecutar esta acción",
                'Error: Could not load "util".',
            ],
        });
    }
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

if (environment.hmr) {
    if (module["hot"]) {
        hmrBootstrap(module, bootstrap);
    } else {
        console.error("HMR is not enabled for webpack-dev-server!");
        console.log("Are you using the --hmr flag for ng serve?");
    }
} else {
    bootstrap().catch((err) => console.log(err));
}

